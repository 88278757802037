@import '@/_styles/import';
.MNVVOfferBanner {
  overflow: hidden;
  display: flex;
  align-items: center;
  align-self: stretch;

  min-height: 129px;
  margin-bottom: rem(30);

  background-color: $light-green;
  border-radius: 8px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  &__info {
    display: flex;
    flex: 0 0 68%;
    flex-direction: row;
    align-items: center;

    padding: 16px 20px 16px 30px;

    .early-redemption {
      display: flex;
      flex: 1;
      flex-direction: column;

      width: 50%;
      margin-right: rem(16);

      border-right: 2px solid rgb(60 16 83 / 10%);

      span {
        padding-right: rem(16);
      }

      .account-title {
        font-size: rem(14);
        font-weight: 700;
        line-height: rem(24);
      }

      .full-title {
        font-size: rem(24);
        font-weight: 700;
      }

      .description {
        font-size: rem(13);
        font-weight: 400;
      }
    }

    .account-reservation {
      display: flex;
      flex: 1;
      flex-direction: row;
      gap: 8px;

      img {
        width: 24px;
        height: 24px;
      }

      .reservation-col {
        display: flex;
        gap: 8px;
        align-items: flex-start;

        &__text {
          display: flex;
          flex-direction: column;
          font-size: rem(13);

          .title {
            font-size: rem(14);
            font-weight: 700;
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;

      .early-redemption {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
        padding-bottom: 16px;

        border-right: 0;
        border-bottom: 2px solid rgb(60 16 83 / 10%);
      }
    }

    @include media-breakpoint-down(xs) {
      .early-redemption {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
        border: 0;
      }

      .account-reservation {
        flex-direction: column;
      }

      .reservation-col:first-child {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 2px solid rgb(60 16 83 / 10%);
      }
    }
  }

  &__timer {
    position: relative;

    overflow: hidden;
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    justify-content: flex-end;

    width: 100%;

    color: $white;

    .timer-background {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    .timer-container {
      z-index: 1;

      display: flex;
      align-items: flex-start;
      align-self: stretch;
      justify-content: flex-end;

      width: 100%;
      padding: 35px 16px 35px 24px;

      font-size: rem(14);
      font-weight: 700;

      background: linear-gradient(0deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 20%) 100%);

      @include media-breakpoint-down(sm) {
        padding: 35px 16px 35px 30px;
      }

      .timer-icon {
        z-index: 1;
        width: 80px;
      }

      .timer-countdoun {
        flex: 1;
        padding-right: 18px;
      }
    }
  }
}
